import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { Router as Lingo2Router } from 'lingo2-models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {ErrorNotificationService} from "@shared/error-notification/error-notification.service";

@Component({
  selector: 'app-stub-page',
  templateUrl: './stub-page.component.html',
  styleUrls: ['./stub-page.component.scss'],
})
export class StubPageComponent extends ChangableComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  public unsupportedCategory: string;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private route: ActivatedRoute,
    protected readonly cdr: ChangeDetectorRef,
    protected platform?: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (params) => {
        if (params.type) {
          this.unsupportedCategory = 'content-type-title.' + Lingo2Router.getNameByContentType(params.type);
        }
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
