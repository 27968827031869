<div class="constructor-stub">
  <div class="icon">
    <app-icon
      icon="constructor-stub"
      [width]="120"
      [height]="80">
    </app-icon>
  </div>

  <ng-container *ngIf="!unsupportedCategory; else unsupportedBlock">
    <span class="text normal">
      {{ 'constructor2.stub.text' | translate }}
    </span>
    <span class="advice normal">
      {{ 'constructor2.stub.advice' | translate }}
    </span>
  </ng-container>

  <ng-template #unsupportedBlock>
    <div class="text normal" [innerHTML]="'constructor2.stub.unsupported-type' | translate: {'type': ('' + (unsupportedCategory | translate) + '')}"></div>
  </ng-template>
</div>