import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/icon/icon.component';
import { StubPageComponent } from './stub-page.component';

@NgModule({
  declarations: [StubPageComponent],
  imports: [CommonModule, TranslateModule, IconComponent],
  exports: [StubPageComponent],
})
export class StubPageModule {}
